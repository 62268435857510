<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >
    <b-modal
        id="createCredentialConditionModal"
        centered
        title="Tạo yêu cầu cho văn bằng, chứng chỉ"
        size="xl"
        body-class="position-static"
        no-close-on-backdrop
        hide-footer
        @show="onShow"
        @hide="onHide"
    >
      <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
          <div class="card">
            <div class="card-body">
              <h3>
                Thông tin văn bằng, chứng chỉ
              </h3>
              <div class="info-container">
                <ul class="list-unstyled">
                  <li class="mb-1 mt-2">
                    <span class="fw-medium me-1">
                      Tên văn bằng, chứng chỉ:
                    </span>
                    <strong>
                      {{ infoCredential.credentialName }}
                    </strong>
                  </li>
                  <li class="mb-1 pt-1">
                    <span class="fw-medium me-1">
                      Tên sinh viên:
                    </span>
                    <strong>
                      {{ infoCredential.name }}
                    </strong>
                  </li>
                  <li class="mb-1 pt-1">
                    <span class="fw-medium me-1">
                      Ngày tháng năm sinh:
                    </span>
                    <strong>
                      {{ infoCredential.birthday }}
                    </strong>
                  </li>
                  <li class="mb-1 pt-1">
                    <span class="fw-medium me-1">
                      Nơi sinh:
                    </span>
                    <strong>
                      {{ infoCredential.address }}
                    </strong>
                  </li>
                  <li class="mb-1 pt-1">
                    <span class="fw-medium me-1">
                      Giới tính:
                    </span>
                    <strong>
                      {{ infoCredential.gender }}
                    </strong>
                  </li>
                  <li class="mb-1 pt-1">
                    <span class="fw-medium me-1">
                      Dân tộc:
                    </span>
                    <strong>
                      {{ infoCredential.ethnic }}
                    </strong>
                  </li>
                  <li class="mb-1 pt-1">
                    <span class="fw-medium me-1">
                      Năm cấp:
                    </span>
                    <strong>
                      {{ infoCredential.year }}
                    </strong>
                  </li>
                  <li class="mb-1 pt-1">
                    <span class="fw-medium me-1">
                      Số hiệu chứng chỉ:
                    </span>
                    <strong>
                      {{ infoCredential.code }}
                    </strong>
                  </li>
                  <li class="mb-1 pt-1">
                    <span class="fw-medium me-1">
                      Số vào sổ gốc:
                    </span>
                    <strong>
                      {{ infoCredential.codeCredentialStorage }}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
          <div class="card">
            <div class="card-body">
              <h3> Danh sách yêu cầu </h3>
              <div class="mb-3">
                <div class="dataTables_wrapper dt-bootstrap5 no-footer">
                  <b-row class="mt-1">
                    <b-col cols="12">
                      <vue-good-table
                          :columns="columns"
                          :rows="credentialProcessTypes"
                          :pagination-options="paginationOptions"
                          :line-numbers="true"
                          @on-row-click="onSelectCredentialCondition"
                      >
                        <div
                            slot="emptystate"
                            style="text-align: center; font-weight: bold"
                        >
                          Không có bản ghi nào !
                        </div>
                      </vue-good-table>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h3> Tạo đơn yêu cầu </h3>
              <b-row
                  v-if="currentCredentialCondition"
                  class="mt-1"
              >
                <b-col cols="12">
                  <b-row>
                    <b-col>
                      <p><strong>Tên yêu cầu: </strong>{{ currentCredentialCondition.name }}</p>
                      <p><strong>Mẫu file yêu cầu: </strong>
                        <a
                            v-for="(item, index) in filesRequired"
                            :key="index"
                            ref="row"
                            :href="item.filePath"
                            :download="`${item.fileName}.docx`"
                            @click.prevent="downloadFile(item.filePath, item.fileName)"
                        > <feather-icon icon="DownloadIcon"/> File: {{item.name}} </a>
                      </p>
                      <p style="color: red; font-style: italic;"> Sinh viên cần tải đầy đủ mẫu đơn và điền thông tin theo mẫu </p>
                      <b-form>
                        <b-form-group label-for="name">
                          <template v-slot:label>
                            Mô tả yêu cầu<span class="text-danger">*</span>
                          </template>
                          <validation-provider
                              #default="{ errors }"
                              name="Mô tả yêu cầu"
                              rules="required"
                          >
                            <b-form-input
                                id="name"
                                v-model="targetItem.name"
                                placeholder="Nhập mô tả yêu cầu"
                                :state="getElementState(errors)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                            v-for="(item, index) in filesRequired"
                            :key="index"
                            ref="row"
                        >
                          <template v-slot:label>
                            Tải lên file yêu cầu ứng với "{{item.name}}"
                          </template>
                          <b-form-file
                              ref="inputFileRef"
                              @change="event => uploadFile(event, index)"
                          />
                        </b-form-group>
                      </b-form>
                    </b-col>
                  </b-row>
                  <div class="w-100 d-flex justify-content-end">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        @click="onSave('hide')"
                    >
                      <span class="text-right">
                        <feather-icon icon="CheckIcon" /> Lưu lại
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </validation-observer>
</template>
<script>
import {
  BButton,
  BButtonGroup,
  BCard, BCardBody, BCardHeader, BCardTitle,
  BCol,
  BContainer,
  BDropdownItem, BFormFile,
  BFormGroup, BFormInput,
  BFormSelect, BModal,
  BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'CreateCredentialCondition',
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BDropdownItem,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BRow,
    BFormInput,
    BFormFile,
    Ripple,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isAllowRegister: true,
      isLoading: false,
      user: getUser(),
      filter: {
        type: null,
        name: null,
      },
      filterAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
      currentCredential: undefined,
      paginationOptions: {
        enabled: false,
      },
      currentCredentialCondition: null,
      infoCredential: {
        credentialName: '',
        name: '',
        birthday: '',
        address: '',
        gender: '',
        ethnic: '',
        year: '',
        code: '',
        codeCredentialStorage: '',
      },
      targetItem: {
        studentId: '',
        credentialId: '',
        credentialStorageId: '',
        certificateProcessTypeId: '',
        credentialProcessTypeId: '',
        name: '',
        filesId: [],
        status: 0,
      },
      filesRequired: [],
      columns: [
        {
          label: 'Tên yêu cầu',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      credentialProcessTypes: 'credentialCondition/credentialProcessTypes',
      credentialFilesRequire: 'credentialCondition/credentialFilesRequire',
    }),
  },
  async created() {
    this.infoCredential = this.item || this.infoCredential
  },
  methods: {
    ...mapActions({
      readCredentialProcessTypes: 'credentialCondition/readCredentialProcessTypes',
      readCredentialFileRequire: 'credentialCondition/readCredentialFileRequire',
      uploadSample: 'credentialCondition/uploadSample',
      getPathFileSample: 'credentialCondition/getPathFileSample',
      createCredentialProcessRequest: 'credentialCondition/createCredentialProcessRequest',
    }),
    async onSelectCredentialCondition(row) {
      if (this.currentCredentialCondition === row.row) {
        this.currentCredentialCondition = null
        this.filesRequired = []
      } else {
        this.filesRequired = []
        this.currentCredentialCondition = row.row
        this.targetItem.certificateProcessTypeId = this.currentCredentialCondition.certificateProcessTypeId
        this.targetItem.credentialProcessTypeId = this.currentCredentialCondition.id
        this.targetItem.studentId = this.user.studentId
        this.targetItem.credentialId = this.item.credentialId
        this.targetItem.credentialStorageId = this.item.id
        await this.readCredentialFileRequire({ id: this.currentCredentialCondition.id })
        for (const file of this.credentialFilesRequire) {
          const { fileName, name } = file
          const filePath = await this.getPathFileSample({
            pathFile: fileName,
            contentType: 'application/octet-stream',
          })
          this.filesRequired.push({ filePath, fileName, name })
        }
      }
    },
    async onShow() {
      this.infoCredential = this.item || this.infoCredential
      await this.readCredentialProcessTypes({
        id: this.item.credentialId,
        params: this.filterAll,
      })
    },
    onHide() {
      this.currentCredentialCondition = null
      this.targetItem = {
        studentId: '',
        credentialId: '',
        credentialStorageId: '',
        certificateProcessTypeId: '',
        credentialProcessTypeId: '',
        name: '',
        filesId: [],
        status: 0,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    async uploadFile(e, index) {
      this.isLoading = true;
      const formData = new FormData();
      const { files } = e.target;
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File tài liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        });
        this.isLoading = false;
        return;
      }

      files.forEach(file => formData.append('files', file));

      this.mediaIds = await this.uploadSample(formData);

      if (this.mediaIds.length > 0) {
        if (this.targetItem.filesId[index] !== undefined && this.targetItem.filesId[index] !== null) {
          // Replace the old file ID with the new one
          this.targetItem.filesId[index] = this.mediaIds[0];
        } else {
          // Add the new file ID
          this.targetItem.filesId.push(this.mediaIds[0]);
        }
        this.showToast('Upload file thành công', 'CheckIcon', 'success');
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger');
      }
      this.isLoading = false;
    },
    downloadFile(fileUrl, fileName) {
      const link = document.createElement('a')
      link.href = fileUrl
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        if (this.currentCredentialCondition.code !== 'ADD_CREDENTIAL' && !this.targetItem.credentialStorageId){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Chưa có thông tin về văn bằng của sinh viên, vui lòng quay lại lựa chọn',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          this.isLoading = false
          return
        }
        try {
          const response = await this.createCredentialProcessRequest(this.targetItem)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('createCredentialConditionModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
</style>
