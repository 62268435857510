import { render, staticRenderFns } from "./CredentialsOfStudent.vue?vue&type=template&id=7302392b&scoped=true"
import script from "./CredentialsOfStudent.vue?vue&type=script&lang=js"
export * from "./CredentialsOfStudent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7302392b",
  null
  
)

export default component.exports